import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Layout'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { translate, activeCountries } from '../../constants/translations'
import useCases from '../../constants/useCases'
import searches from '../../constants/searches'

import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Typography'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'

import telephones from '../../constants/telephones'

const Shape1 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 300px 300px 0px;
`

const Shape4 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 30vw;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 300px 300px 0px;
`

const Shape2 = styled(Box)`
  position: absolute;
  left: 30vw;
  top: 0;
  width: 35vw;
  height: 85vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 0px 300px 0px;
  z-index: 1;
`

const Shape3 = styled(Box)`
  position: absolute;
  left: 65vw;
  top: 20vh;
  width: 30vw;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 0px 0px 0px;
  z-index: 1;
`

const Left = styled(Box)`
  z-index: 1;
  padding-left: 200px;
  @media (max-width: 1250px) {
    padding-left: 100px;
    margin-top: 150px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    margin-top: 100px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    margin-top: 50px;

    padding-left: 16px;
    padding-right: 16px;
  }
`

const ResponsivePaddingFlex = styled(Flex)`
  padding-left: 200px;
  padding-right: 200px;
  @media (max-width: 1250px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Header = styled(H1)`
  color: white;
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 32px;
  @media (max-width: 500px) {
    font-size: 40px;
    line-height: 50px;
  }
`

const SubHeader = styled(H2)`
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const CountryHeader = styled(H3)`
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const FlagImage = styled.img`
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const CounterContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 32px;
  @media (max-width: 700px) {
    top: 8px;
    right: 18px;
  }
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
`

const Counter = ({ count }) => {
  return (
    <CounterContainer>
      <Span fontSize="12px" bold>
        {count}
      </Span>
    </CounterContainer>
  )
}

const CountryContainer = styled(Flex)`
  max-height: 120px;
  height: 120px;
  width: 120px;
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${colors.lightBlueHover};
  }
  @media (max-width: 700px) {
    padding-top: 16px;
    height: 90px;
    width: 90px;
    margin: 8px;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 330px) {
    margin: 8px;
  }
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const Grid = styled(Flex)`
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
  }
`

const IconContainer = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 24px;
  padding: 22px;
  margin-bottom: 32px;
`

const MockupStyler = styled(Flex)`
  .phoneMockup {
    position: absolute;
    top: 0;
    right: -200px;
    @media (max-width: 900px) {
      right: -100px;
    }
    @media (max-width: 700px) {
      right: -50px;
    }
    @media (max-width: 500px) {
      right: -100px;
    }
  }
  .desktopMockup {
    position: absolute;
    top: 0;
    right: -100px;
    @media (max-width: 900px) {
      right: 100px;
    }
    @media (max-width: 700px) {
      right: 0px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
`

const NumberContainer = styled(Flex)`
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 24px;
  padding: 22px;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 16px;
`

const NumberCount = ({ number }) => {
  return (
    <NumberContainer>
      <Text fontSize="40px" fontWeight="500" color={colors.lightBlue}>
        {number}
      </Text>
    </NumberContainer>
  )
}

const HowStep = ({ number, text }) => {
  return (
    <Flex alignItems="center" mb="32px" maxWidth="500px">
      <NumberCount number={number} />
      <Text fontSize="18px">{text}</Text>
    </Flex>
  )
}

const RowColumnResponsive = styled(Flex)`
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`

const HeroContainer = styled(Flex)`
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0px 0px 100px 0px;
    height: 75vh;
    max-height: 500px;
  }
`

const BackgroundImageContainer = styled(Box)`
  @media (max-width: 500px) {
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 00px;
  }
  .heroPhone {
    width: 40vw;
    @media (max-width: 1000px) {
      margin-top: 100px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const UseCaseContainer = styled(ResponsivePaddingFlex)`
  @media (max-width: 500px) {
    border-radius: 200px 0px 0px 0px;
  }
`

const MobileMenu = styled(MobileContainer)`
  height: 60px;
  background-color: ${colors.darkBlue};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const IndexPage = () => {
  return (
    <Layout>
      <Helmet
        title={translate(`Receive SMS online | 18+ Countries | 100% Free`, 'home.title', 'gb')}
        htmlAttributes={{
          lang: 'en'
        }}
        description={translate(
          `The largest collection of temporary phone numbers on the internet. 18+ Countries, 100+ numbers. 100% free, no registration.`,
          'home.description',
          'gb'
        )}
        url="https://receiveasmsonline.com/"
      >
        <html lang="en" />
        <link rel="alternate" hrefLang="en" href={encodeURI(`https://receiveasmsonline.com/`)} />
        {activeCountries.map((iso2) => (
          <link rel="alternate" hrefLang={iso2} href={encodeURI(`https://receiveasmsonline.com/${iso2}/`)} />
        ))}
      </Helmet>
      <MobileMenu>
        <a href={`/`}>
          <Flex width="auto" alignItems="flex-end">
            <Image alt="home icon" mr="14px" width="30px" height="30px" src="/home.svg" />
          </Flex>
        </a>
        <Flex width="auto" alignItems="flex-end" onClick={() => document.getElementById('country-container').scrollIntoView()}>
          <Text bold mr="14px" color="white">
            {translate(`All numbers`, 'allnumbers', 'gb')}
          </Text>
          <Image alt="number icon" mt="1px" width="30px" height="30px" src="/number.svg" />
        </Flex>
      </MobileMenu>
      <HeroContainer
        width="100vw"
        height="80vh"
        justifyContent="space-between"
        bg={colors.darkBlue}
        borderRadius="0px 0px 300px 0px"
        overflow="hidden"
        position="relative"
        maxHeight="700px"
      >
        <Shape1 />
        <Shape2 />
        <Shape3 />
        <Shape4 />
        <Left mt="200px">
          <Header>{translate('Receive SMS for free', `home.header`, 'gb')}</Header>
          <SubHeader>
            {translate('The largest collection of temporary phone numbers on the internet. 100% free, no registration.', `home.subheader`, 'gb')}
          </SubHeader>
        </Left>
        <BackgroundImageContainer position="relative">
          <StaticImage alt="mobile phone mockup" className="heroPhone" src="../images/heroPhone.png" />
        </BackgroundImageContainer>
      </HeroContainer>
      <ResponsivePaddingFlex marginTop="-100px" flexDirection="column" zIndex={1} mb="100px" id="country-container">
        <CountryHeader>{translate('Browse numbers by country:', 'browsenumbercountry', 'gb')}</CountryHeader>
        <Grid>
          {Object.keys(telephones).map((otherCountry) => {
            return (
              <a key={otherCountry} href={`/${otherCountry}/`}>
                <CountryContainer>
                  <Flag iso2={countryToIso2[otherCountry]} />
                  <Counter count={telephones[otherCountry].length} />
                  <Text lineHeight="18px" px={2} textAlign="center" A>
                    {urlToString(otherCountry)}
                  </Text>
                </CountryContainer>
              </a>
            )
          })}
        </Grid>
      </ResponsivePaddingFlex>
      <Flex justifyContent="center">
        <Flex borderRadius="24px" bg="white" width="fit-content" p="24px" alignItems="center" justifyContent="center" mb="100px">
          <Flex mr="16px" bg={colors.lightBlue} width="100px" height="100px" borderRadius="100px" alignItems="center" justifyContent="center">
            <Image width="100px" height="100px" src="/account.svg" />
          </Flex>
          <Flex flexDirection="column" width="auto">
            <H4 mb="8px" fontWeight="300">
              No account required - all our mobile numbers are available without registration
            </H4>
            <Text fontSize="18px">
              and{' '}
              <Span fontSize="22px" bold>
                100%
              </Span>{' '}
              for free!
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ResponsivePaddingFlex zIndex={1} flexDirection="column" alignItems="center" bg={colors.lightGrey} mb="60px">
        <H4 fontSize="32px" mb="40px">
          How to get started
        </H4>
        <RowColumnResponsive>
          <Flex flexDirection="column" alignItems="center">
            <HowStep number={1} text="Select the country and choose an available phone number" />
            <HowStep number={2} text="Send an SMS to the selected telephone number" />
            <HowStep number={3} text="Press refresh messages and wait for your reply to appear on the page" />
            <HowStep number={'!'} text="All messages are public so avoid sending personal information" />
          </Flex>
          <MockupStyler position="relative" minHeight="400px">
            <StaticImage alt="desktop mockup" className="desktopMockup" width={600} src="../images/desktopMockup.png" />
            <StaticImage alt="phone mockup" className="phoneMockup" width={500} src="../images/phoneMockup.png" />
          </MockupStyler>
        </RowColumnResponsive>
      </ResponsivePaddingFlex>
      <UseCaseContainer zIndex={1} flexDirection="column" alignItems="center" bg="white" pt="100px" pb="200px" borderRadius="300px 0px 0px 0px">
        <Text fontSize="16px" mb="8px">
          Multiple use case
        </Text>
        <H4 fontSize="32px" lineHeight="40px" mb="40px" textAlign="center">
          Disposable Phone Numbers
        </H4>
        <RowColumnResponsive>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#E3FFEE">
              <Image alt="verify icon" width={56} height={56} src="/verify.svg" />
            </IconContainer>

            <Text bold fontSize="18px" mb="16px">
              Verification
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              Need more than one account? Use our numbers for text verification for uber, wechat, whatsapp, netflix and more.
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#ECF2FF">
              <Image alt="plane icon" width={56} height={56} src="/plane.svg" />
            </IconContainer>

            <Text bold fontSize="18px" mb="16px">
              International Phone numbers
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              Want to watch foreign netflix? We have mobile numbers from 10+ different countries.
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#FFECEF">
              <Image alt="free icon" width={56} height={56} src="/free.svg" />
            </IconContainer>
            <Text bold fontSize="18px" mb="16px">
              Free Temporary Numbers
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              All our virtual numbers are 100% free to use.
            </Text>
          </Flex>
        </RowColumnResponsive>
      </UseCaseContainer>
      <ResponsivePaddingFlex flexDirection="column" my="40px">
        <H4 fontSize="32px" mb="40px">
          Other Use Cases:
        </H4>
        <Flex flexWrap="wrap">
          {useCases.concat(searches).map((useCase) => {
            return (
              <a href={`/${stringToUrl(useCase)}/`}>
                <PillButton mb="16px" mr="16px" label={useCase} />
              </a>
            )
          })}
        </Flex>
      </ResponsivePaddingFlex>
      <Footer iso2={'en'} />
    </Layout>
  )
}

export default IndexPage
